import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import { mapMapLayers } from '@/utils/mapLayers';
import MapLayer from '@/interfaces/mapLayers/MapLayer';
import mapLayersApolloClient from '@/lib/appsync/mapLayers';
import cleanDataApolloClient from '@/lib/appsync/cleanData';
import MapLayerResponse from '@/graphql/mapLayers/interfaces/MapLayerResponse';
import { v4 as uuidv4 } from 'uuid';

import getMapLayers from '@/graphql/mapLayers/mutations/getMapLayers.graphql';
import GetMapLayers from '@/graphql/mapLayers/interfaces/GetMapLayers';

import deleteMapLayerMutation from '@/graphql/mapLayers/mutations/deleteMapLayer.graphql';

import getMetadataLatLong from '@/graphql/mapLayers/queries/getMetadataLatLong.graphql';

import accountModule from '@/store/modules/accountModule';
import { getNameByEmail } from '@/utils/users';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'mapLayersModule',
  store,
})
class MapLayersModule extends VuexModule {
  mapLayerList: MapLayer[] = [];
  metadataTableWellsRecord = {}

  @Mutation
  setMapLayerList({ data, listType = 'mapLayerList' }: {
    data: MapLayer[],
    listType?: string,
  }): void {
    this[listType] = data;
  }

  @Mutation
  resetMapLayer(): void {
    this.mapLayerList = []
  }



  @Mutation
  addMapLayer(record): void {
    this.mapLayerList.push(record)
  }


  @Mutation
  setMetadataTableWellsRecord(record): void {
    this.metadataTableWellsRecord = record
  }








@Action
async deleteMapLayer({
  maplayer_id
}) {
  try {
    const {
      data: {
      },
    } = await mapLayersApolloClient.mutate({
      mutation: deleteMapLayerMutation,
      variables: {
        input: {
			MapLayerID: maplayer_id
        },
      },
    });
  } catch (e) {
    console.error(e);
    throw new Error(e);
  }
}







  @Action
  async getMetadataLatLong(): Promise<void> {
    const {
      data: {
        get_metadata_latlong: metadataLatLong,
      },
    } : any = await cleanDataApolloClient.query({
      query: getMetadataLatLong,
      variables: {
          operator: getConfigEnv('OPERATOR_LOWERCASED')
      },
    });
	var record = {
		"assetType": "METADATA_TABLE_WELLS",
		"assetTypeReadable": "Wells",
		"bucket": "metadata_wells",
		"enabled": true,
		"geoJson": JSON.parse(metadataLatLong.result),
		"geoJsonURL": "metadata_wells",
		"id": uuidv4(),
		"key": "metadata_wells",
		"operator": getConfigEnv('OPERATOR_LOWERCASED'),
		"shapefileName": "metadata_wells",
		"shapefileZipURL": "metadata_wells",
		"time": "",
		"layerName": "Wells",
		"username": getNameByEmail(accountModule.user.email.toLowerCase())
	}
	this.setMetadataTableWellsRecord(record)
  }










  @Action
  async getMapLayers(): Promise<void> {
	this.resetMapLayer()
    const {
      data: {
        get_maplayers: mapLayerResults,
      },
    }: GetMapLayers = await mapLayersApolloClient.query({
      query: getMapLayers,
      variables: {
        input: {
          Operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      },
    });
	this.resetMapLayer()
    const mapLayerResultsCleaned: any[] = [];
    for (let x = 0; x < mapLayerResults.results.length; x++) {
      mapLayerResultsCleaned.push(JSON.parse(mapLayerResults.results[x]));
    }
    /* eslint-enable */

    const mapLayers = mapLayerResultsCleaned.map(
      (t: MapLayerResponse) => mapMapLayers(t),
    );
    this.setMapLayerList({
      data: [
        ...mapLayers,
      ] as MapLayer[],
    });
  }

  get mapLayers() {
    return this.mapLayerList;
  }

  get mapLayerByID() {
    return (id: string): MapLayer|undefined => this.mapLayers.find((t) => t.id === id);
  }
}

export default getModule(MapLayersModule);
