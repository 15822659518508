/*eslint-disable*/

import { ucfirst } from '@/utils/helpers';
import MapLayer from '@/interfaces/mapLayers/MapLayer';

const mapMapLayers = (mapRecord, userEmail = ''): MapLayer => {
  const mapLayer = {
    id: mapRecord.MapLayerID,
    assetType: mapRecord.AssetType,
	  assetTypeReadable: mapRecord.AssetTypeReadable,
	  selectedAssetNameOption: mapRecord.SelectedAssetNameOption,
	  enabled: mapRecord.Enabled,
    bucket: mapRecord.Bucket,
    geoJsonURL: mapRecord.GeoJsonURL,
	  geoJson: mapRecord.GeoJson,
    key: mapRecord.Key,
    operator: mapRecord.Operator,
    shapefileName: mapRecord.ShapefileName,
    shapefileZipURL: mapRecord.ShapefileZipURL,
    time: mapRecord.time,
    username: mapRecord.Username.toLowerCase(),
	  layerName: mapRecord.LayerName,
  };
  return mapLayer;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  mapMapLayers,
};
